<script setup lang="ts">
import { Modal } from 'jenesius-vue-modal';
import { DialogClose, DialogContent, DialogOverlay, DialogPortal, DialogRoot } from 'radix-vue';

import Tracker from '~/libs/Tracker';

import Utility from '~/services/Utility';
import type { Product } from '~/services/main/type';
import { WEOLBUS_RP_REFERRAL_TEST_TARGET } from '~/services/payment/constant';
import { KakaoShareTemplateType, useShareKakao } from '~/services/share';

import { IconCircleExclamationFilled, cn } from '~/components/5x';
import IconX from '~/components/5x/icons/IconX.vue';
import KakaoIcon from '~/components/common/Icons/KakaoIcon.vue';

export type WeolbusReferralTestModalProps = {
  product: Product;
};
export type WeolbusReferralTestModalReturns = boolean;

const props = defineProps<WeolbusReferralTestModalProps>();
const emit = defineEmits<{ (e: typeof Modal.EVENT_PROMPT, payload: WeolbusReferralTestModalReturns): void }>();

const { myInviteCode } = useUserInviteCode();
const shareKakao = useShareKakao();

const handleVisible = computed<boolean>({
  get: () => true,
  set: (val) => !val && nextTick(() => emit(Modal.EVENT_PROMPT, false)),
});

watch(
  handleVisible,
  () => {
    if (handleVisible.value) {
      Tracker['View Popup']({
        displayName: props.product?.displayName,
        displaySeq: String(props.product?.displaySeq ?? 'none'),
        popupId: 'weolbus-referral',
        popupName: '월부스-레퍼럴',
      });
    }
  },
  { immediate: true, once: true },
);

const handleClick = (type: 'share' | 'close') => {
  switch (type) {
    case 'share': {
      const invitationProduct = WEOLBUS_RP_REFERRAL_TEST_TARGET[props.product.displaySeq as number];

      shareKakao({
        type: KakaoShareTemplateType.COMMON_TYPE01,
        templateArgs: {
          thumb: invitationProduct.kakaoThumb || props.product.displayThumbnailUrl,
          title: invitationProduct.kakaoTitle,
          description: invitationProduct.kakaoDescription,
          buttonText: invitationProduct.kakaoButtonText,
          inviteCode: myInviteCode.value,
          path: `product?displaySeq=${invitationProduct.displaySeq}`,
          utm_source: REFFERAL_UTM_SET['user']['utm_source'],
          utm_campaign: REFFERAL_UTM_SET['user']['utm_campaign'],
          utm_medium: REFFERAL_UTM_SET['user']['utm_medium'],
        },
      });

      Tracker['Click Share Button']({
        type: 'kakao',
        location: 'weolbus-referral',
        currentDisplaySeq: String(props.product.displaySeq),
        invitationDisplaySeq: String(invitationProduct.displaySeq),
        inviteCode: myInviteCode.value,
        url: window.location.href,
        path: '/payment',
      });

      break;
    }
    case 'close': {
      Tracker['Click Button']('weolbus-referral', '닫기', {
        displayName: props.product?.displayName,
        displaySeq: String(props.product?.displaySeq ?? 'none'),
      });
      emit(Modal.EVENT_PROMPT, false);
      break;
    }
  }
};
</script>

<template>
  <DialogRoot :open="handleVisible">
    <DialogPortal>
      <DialogOverlay
        class="fixed inset-0 bg-black/70 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 flex justify-center items-center">
        <DialogContent
          :class="
            cn(
              'relative overflow-hidden',
              'bg-white w-full md:w-[400px] h-full md:h-[526px] md:rounded-2xl',
              'px-4 md:px-7',
            )
          ">
          <div class="h-full flex flex-col justify-center pb-[100px] md:pb-0">
            <p class="text-red-500 text-lg/[26px] md:text-base/6 font-bold text-center">구매 고객 한정 추가 혜택</p>

            <div class="h-2" />

            <p class="text-center font-bold text-3xl/[34px] md:text-2xl/[24px] text-neutral-1000">
              방금 결제한 강의를<br />
              0원으로 선물할 수 있어요!
            </p>

            <div class="h-4" />

            <img
              :src="Utility.getOptimized(props.product.displayThumbnailUrl, 'PRODUCT_THUMBNAIL') || ''"
              :class="cn('w-[230px] h-[130px] block mx-auto rounded-md')" />

            <div class="h-7" />

            <div class="w-fit mx-auto space-y-2">
              <div class="relative">
                <p
                  class="bg-[#FD4A6E] text-white px-3 py-0.5 max-w-[144px] text-sm/5 font-bold rounded-[100px] mx-auto text-center">
                  아무 조건없이 누구나!
                </p>
                <svg
                  class="absolute right-[29.5px] -bottom-[7px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="8"
                  viewBox="0 0 10 8"
                  fill="none">
                  <path
                    d="M5.86603 7.5C5.48113 8.16667 4.51887 8.16667 4.13397 7.5L0.669874 1.5C0.284974 0.833333 0.7661 -8.94676e-07 1.5359 -8.27378e-07L8.4641 -2.21695e-07C9.2339 -1.54397e-07 9.71503 0.833333 9.33013 1.5L5.86603 7.5Z"
                    fill="#FD4A6E" />
                </svg>
              </div>

              <p class="flex items-center justify-center space-x-2">
                <span class="line-through text-neutral-500 text-2xl/[30px]">
                  {{ Utility.setCommaPer3Digit(props.product.price) }}원
                </span>
                <span class="text-5xl/[48px] font-bold"> 0원 </span>
              </p>
            </div>

            <div class="h-5" />

            <button
              :class="
                cn(
                  'bg-[#FEE500] text-black rounded w-full max-w-[343px] h-15 mx-auto flex justify-center items-center space-x-2',
                  'hover:opacity-80 hover:cursor-pointer',
                  'text-sm/[18px] font-bold',
                )
              "
              @click="handleClick('share')">
              <KakaoIcon class="w-6 h-6" />
              <span> 카톡으로 선물하기 </span>
            </button>

            <div class="h-2" />

            <p class="text-sm/4 font-semibold flex items-center justify-center space-x-1">
              <IconCircleExclamationFilled size="18" class="text-red-500" />
              <span class="text-neutral-800"> 이 화면을 닫으면 혜택이 사라져요! </span>
            </p>
          </div>

          <div class="absolute top-4 md:right-4 hover:cursor-pointer" @click="handleClick('close')">
            <DialogClose as-child>
              <IconX size="24" class="text-black md:text-neutral-500" />
            </DialogClose>
          </div>
        </DialogContent>
      </DialogOverlay>
    </DialogPortal>
  </DialogRoot>
</template>

<style scoped></style>
