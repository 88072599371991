import { promptModal } from 'jenesius-vue-modal';

import WeolbusReferralTestModal from '~/services/payment/component/WeolbusReferralTestModal/WeolbusReferralTestModal.vue';
import type {
  WeolbusReferralTestModalProps,
  WeolbusReferralTestModalReturns,
} from '~/services/payment/component/WeolbusReferralTestModal/WeolbusReferralTestModal.vue';

export const openWeolbusReferralTestModal = (
  props: WeolbusReferralTestModalProps,
): Promise<WeolbusReferralTestModalReturns> => {
  return new Promise((resolve) => {
    if (!$isClient()) {
      return;
    }

    nextTick(async () => {
      resolve((await promptModal(WeolbusReferralTestModal, { ...props })) as Promise<WeolbusReferralTestModalReturns>);
    });
  });
};
