<script setup lang="ts">
import Tracker, { type ClickLoginEventProps, type ClickSignupEventProps } from '~/libs/Tracker';

import Utility from '~/services/Utility';
import { useBasicsStore } from '~/services/base';
import { useMainStore } from '~/services/main';
import { useUserAuthStore } from '~/services/userAuth';

import AuthClient from '~/shared/auth/client';

const authStore = useUserAuthStore();
const mainStore = useMainStore();
const router = useRouter();
const isClient = $isClient;
const basicStore = useBasicsStore();
const route = useRoute();
const authClient = ref<AuthClient | null>(null);

const eventParams: ClickSignupEventProps = {
  location: 'hamburger',
  url: process.client ? window.location.href : '',
  path: route.path,
  ...route.query,
  ...route.params,
};

const navToSignup = () => {
  if (route.path.includes('/product')) {
    eventParams['productId'] = mainStore.selectedProduct?.productSeq;
    eventParams['displayId'] = mainStore.selectedProduct?.displaySeq;
    eventParams['isWeolbuStudy'] = Utility.isWeolbuStudy(mainStore.selectedProduct.productCategorySeq) ? 'Y' : 'N';
  }

  Tracker['Click Signup Button'](eventParams);
  basicStore.setLoginModalState(true);
};

const navToLogin = () => {
  if (route.path.includes('/product')) {
    eventParams['productId'] = mainStore.selectedProduct?.productSeq;
    eventParams['displayId'] = mainStore.selectedProduct?.displaySeq;
    eventParams['isWeolbuStudy'] = Utility.isWeolbuStudy(mainStore.selectedProduct.productCategorySeq) ? 'Y' : 'N';
  }

  Tracker['Click Login Button'](eventParams);
  basicStore.setLoginModalState(true);
};
onMounted(() => {
  if (isClient()) {
    authClient.value = AuthClient.create();
  }
});
</script>

<template>
  <div v-if="isClient()" class="wb-header__login">
    <CustomLink v-if="!!authStore.user" :to="$getRoute().mypageStudyroom()">
      <Button severity="secondary" outlined size="small">
        <UserAvatar :image="authStore.user.userProfileImgUrl" />
        내 강의실
      </Button>
    </CustomLink>

    <div v-else class="button-set-wrapper">
      <div class="default-button" @click="navToLogin">로그인</div>

      <img class="vertical-divider" src="/icon/VerticalDivider.png" alt="vertical divider" />

      <div class="default-button signup-button" @click="navToSignup">회원가입</div>
    </div>
  </div>
</template>

<style scoped>
.button-set-wrapper {
  display: flex;
  gap: 7px;
  height: 42px;
  margin-left: 8px;
  align-items: center;
}
.default-button {
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
}

.vertical-divider {
  height: 16px;
  padding-left: 2px;
  padding-right: 2px;
}

.signup-button {
  color: #1f5af2;
}
</style>
