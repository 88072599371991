<script setup lang="ts">
import { first } from 'lodash-es';
import { get } from 'lodash-es';

import { useMobile } from '~/composables/useMobile';

import Tracker from '~/libs/Tracker';
import { safeDateParse } from '~/libs/dateUtils';

import Utility from '~/services/Utility';
import { useMainStore } from '~/services/main';
import { type MyPageStudyRoomClassOptions, myPageService, useMyPageStore } from '~/services/mypage';
import { usePaymentStore } from '~/services/payment';
import { openWeolbusReferralTestModal } from '~/services/payment/component/WeolbusReferralTestModal/openWeolbusReferralTestModal';
import { WEOLBUS_DESCRIPTION_TEST_TARGETS, WEOLBUS_RP_REFERRAL_TEST_TARGET } from '~/services/payment/constant';
import { ProductCategorySeq } from '~/services/product/constant';
import { useSurveyStore } from '~/services/survey';

import { XButtonMo, XButtonPC } from '~/components/common/Icons';

const router = useRouter();
const route = useRoute();
const { isDesktop } = useMobile();

const interval = ref(null);
const emits = defineEmits<{
  (e: 'onPopupClose'): void;
}>();

const surveyStore = useSurveyStore();
const myPageStore = useMyPageStore();
const paymentStore = usePaymentStore();
const store = useMainStore();
const INIT_SEARCH_PARAM = (): MyPageStudyRoomClassOptions => ({
  pageNo: 1,
  pageSize: 6,
  orderColumn: '',
  orderType: '',
  searchFilterType: '',
});
const params = reactive<MyPageStudyRoomClassOptions>(INIT_SEARCH_PARAM());

const handleVisible = computed<boolean>({
  get: () => {
    return store.completePurchasePopup;
  },
  set: (val) => {
    store.setCompletePurchasePopup(val);
  },
});

const selectedOption = computed(() => {
  if ($isEmpty(store.selectedProduct.productOption)) {
    return null;
  }
  return store.selectedProduct.productOption?.find((v) => {
    return `${v.optionSeq}` === `${route.query?.optionSeq}`;
  });
});

// 조편성 대상인지 체크
const hasGroupManagement = computed(() => {
  if (selectedOption.value) {
    return selectedOption.value.optionTypeCd === 'GROUP' || selectedOption.value.optionTypeCd === 'OFFLINE_GROUP';
  }

  return store.selectedProduct.groupYn === 'Y';
});

const isGiftCard = computed(() => store.selectedProduct?.displayType === 'GIFT_CARD');

const isWeolbuStudyProduct = computed(() => {
  if (
    ![ProductCategorySeq.WEOLBU_STUDY_ZERO, ProductCategorySeq.WEOLBU_STUDY_TR_RP as string].includes(
      `${store.selectedProduct.productCategorySeq}` as ProductCategorySeq,
    )
  ) {
    return false;
  }

  return store.selectedProduct.isLiveStudy;
});

const weolbuStudyLiveTimeString = computed(() => {
  if (!store.selectedProduct.liveStudyStartAt) {
    return '';
  }

  const startDate = safeDateParse(store.selectedProduct.liveStudyStartAt);

  return safeDateParse(startDate).format(startDate.minute() ? 'M월D일 Ah시m분' : 'M월D일 Ah시');
});

const weolbuStudyCTAString = computed(() => {
  return store.selectedProduct.weolbuStudyPaymentCompleteButtonText || '';
});

const moveToStudyroom = async () => {
  try {
    // 결제완료 후 간헐적으로 데이터가 안불러와지는 현상이 있어서 1초 간격으로 데이터를 가져옴
    const items = await getMyStudyRoomClasses();
    const targetStudy = items.filter((item) => item.displaySeq === store.selectedProduct.displaySeq);
    router.replace({
      path: `/mypage/studyroom/lecture`,
      query: {
        studySeq: targetStudy[0]?.studySeq,
        displaySeq: targetStudy[0]?.displaySeq,
        optionSeq: route.query?.optionSeq || 0,
      },
    });
  } catch (err) {
    console.log('err :>> ', err);
    router.replace({ path: `/mypage/studyroom`, query: { tab: 0 } });
  }
};

const handleDialogButton = useThrottleFn(async (direction: boolean | string = false) => {
  if (isReferralTestProduct.value) {
    // 닫기를 눌러도 확인을 눌러도 선물하기로 이동
    store.setCompletePurchasePopup(false);
    await openWeolbusReferralTestModal({ product: store.selectedProduct });
    router.replace({
      path: '/mypage/studyroom',
      query: { tab: 2, optionSeq: route.query?.optionSeq || 0 },
    });
    return;
  }

  if (direction === 'weolbu-study') {
    window.open(store.selectedProduct.weolbuStudyPaymentCompleteLandingUrl, '_blank');
    Tracker['Click Button']('live-questions', weolbuStudyCTAString.value, {
      displaySeq: `${store.selectedProduct.displaySeq}`,
    });

    await moveToStudyroom();
    store.setCompletePurchasePopup(false);

    return;
  }

  if (direction === 'purchase-history') {
    router.replace({ path: '/mypage/payment' });
    store.setCompletePurchasePopup(false);

    return;
  }

  if (direction === 'product-detail') {
    // 상품권인 경우 상페로 이동하면 안되므로 이동 중지 처리
    if (isGiftCard.value) {
      return;
    }
    router.replace({ path: '/product', query: { displaySeq: store.selectedProduct.displaySeq } });
    store.setCompletePurchasePopup(false);

    return;
  }

  if (direction === 'survey') {
    const {
      data: { items: surveyList },
    } = await myPageStore.fetchMyPageSurvey(params, false);
    const filteredSurvey = surveyList.filter((item) => item.displaySeq === store.selectedProduct.displaySeq);

    const targetSurvey = first(filteredSurvey);
    if (!targetSurvey) {
      return;
    }

    const { surveySeq, studySeq } = targetSurvey;

    router.replace({
      path: '/mypage/studyroom',
      query: { tab: 2, optionSeq: route.query?.optionSeq || 0, surveySeq, studySeq },
    });
    store.setCompletePurchasePopup(false);

    return;
  }

  if (direction === 'gift-card') {
    router.replace({ path: '/mypage/gift-card' });
    store.setCompletePurchasePopup(false);
    return;
  }

  if (hasGroupManagement.value) {
    router.replace({
      path: '/mypage/studyroom',
      query: { tab: 2, optionSeq: route.query?.optionSeq || 0 },
    });
    store.setCompletePurchasePopup(false);

    return;
  }

  await moveToStudyroom();
  store.setCompletePurchasePopup(false);
}, 1000);

const isSuccessDescriptionTestProduct = computed(() => {
  // 월부스 : 결제 성공시 문구 분기처리
  return WEOLBUS_DESCRIPTION_TEST_TARGETS.includes(store.selectedProduct?.displaySeq as number);
});

const isReferralTestProduct = computed(() => {
  // RP 고객이 월부스 구매 => 레퍼럴 동작하는지 테스트하는 상품들
  return !!WEOLBUS_RP_REFERRAL_TEST_TARGET[store.selectedProduct?.displaySeq as number];
});

// 조편성 설문 팝업 열기
const openSurveyDialog = async () => {
  // 조편성 목록 데이터 가져오기
  const {
    data: { items: surveyList },
  } = await myPageStore.fetchMyPageSurvey(params, false);
  const filteredSurvey = surveyList.filter((item) => item.displaySeq === store.selectedProduct.displaySeq);
  if (!filteredSurvey.length) {
    return;
  }

  // 가장 최근의 설문의 기간이 만료 되었는지 확인
  if (filteredSurvey[0].surveyClosed) {
    $alert('조편성 설문 기간이 종료되었습니다.');
    return;
  }

  // 조편성 정보 가져오기
  const {
    data: {
      data: { items: _surveyData },
    },
  } = await myPageService.getMyPageSurveyItem(filteredSurvey[0].surveySeq, filteredSurvey[0].studySeq);

  // 조편성 정보 세팅
  surveyStore.setSurveyMode('add');
  surveyStore.setSurveyStudySeq(filteredSurvey[0].studySeq);
  surveyStore.setSurveyPopupData(_surveyData);
  surveyStore.setSurveyPopupVisible(true);
};

const getMyStudyRoomClasses = async () => {
  return new Promise((resolve, reject) => {
    try {
      interval.value = setInterval(() => {
        myPageStore.fetchMyPageStudyRoomClasses(params, false).then(({ items }) => {
          if (items?.length > 0) {
            clearInterval(interval.value);
            resolve(items);
          }
        });
      }, 1000);
    } catch (err) {
      if (interval.value) {
        clearInterval(interval.value);
      }
      reject(err);
    }
  });
};

const textClamp = (text: string) => {
  if (text.length >= 5) {
    return text.slice(0, 5) + '...';
  }
  return text;
};

onBeforeUnmount(() => {
  if (interval.value) {
    clearInterval(interval.value);
  }
});
</script>

<template>
  <Dialog
    v-model:visible="handleVisible"
    :header="title"
    :breakpoints="{ '1199px': '500px', '960px': '100dvw', '640px': '100vw' }"
    :modal="true"
    :closable="false"
    :draggable="false"
    :block-scroll="false"
    class="complete-purchase-dialog">
    <template #header>
      <div v-if="isDesktop && hasGroupManagement" class="button-container" @click="() => handleDialogButton(false)">
        <XButtonPC style="color: #dddddd" />
      </div>
      <div class="text-container">
        <XButtonMo v-if="!isDesktop" style="color: #000" @click="() => handleDialogButton(false)" />
        <div v-if="isDesktop">
          결제가 완료되었습니다 <br />
          <div
            v-if="
              get(store.selectedProduct.creatorNames, '[0]') && !(isWeolbuStudyProduct && !!weolbuStudyLiveTimeString)
            "
            class="message-container__complete-purchase_class-message">
            {{ textClamp(get(store.selectedProduct.creatorNames, '[0]')) }}님과 함께 완강까지 해내길 응원할께요!
          </div>
        </div>
      </div>
    </template>

    <div data-testId="complete-purchase-dialog-message-container" class="message-container__complete-purchase">
      <div v-if="!isDesktop" class="message-container__title">
        결제가 <br />
        완료되었습니다
        <div
          v-if="
            get(store.selectedProduct.creatorNames, '[0]') && !(isWeolbuStudyProduct && !!weolbuStudyLiveTimeString)
          "
          class="message-container__complete-purchase_class-message">
          {{ textClamp(get(store.selectedProduct.creatorNames, '[0]')) }}님과 함께 완강까지 해내길 응원할께요!
        </div>
      </div>

      <div
        class="message-container__product"
        :class="isGiftCard ? 'cursor-default' : ''"
        @click="() => handleDialogButton('product-detail')">
        <div class="message-container__product__thumbnail-img">
          <img :src="Utility.getOptimized(store.selectedProduct.displayThumbnailUrl, 'PRODUCT_THUMBNAIL')" />
        </div>
        <div class="message-container__product__content">
          <div class="product-title">{{ store.selectedProduct.displayName }}</div>
          <div class="product-desc">{{ selectedOption?.optionName }}</div>
        </div>
      </div>
      <div v-if="isWeolbuStudyProduct && !!weolbuStudyLiveTimeString" class="flex flex-col gap-5 w-full">
        <div class="h-[1px] bg-neutral-300 mt-5"></div>
        <p v-if="isSuccessDescriptionTestProduct" class="text-lg text-neutral-1000 font-bold">
          결제하신 월부스터디는
          <br />
          <span class="text-red-600">{{ weolbuStudyLiveTimeString }}에 라이브로 진행됩니다.</span>
          <br />
          라이브 입장 링크는 오픈단톡방에서 전달드리니
          <br />
          꼭 입장해주세요!
        </p>
        <p v-else class="text-lg text-neutral-1000 font-bold">
          결제하신 월부스터디는
          <br />
          <span class="text-red-600">{{ weolbuStudyLiveTimeString }}에 라이브로 진행됩니다.</span>
          <br />
          라이브 당일에 카카오톡 알림 또는 문자로
          <br />
          <span class="text-red-600">입장링크</span>를 보내드려요.
        </p>
        <div class="text-sm text-neutral-600 font-normal">
          월부스터디 혜택을 놓치지 않도록
          <br />
          알림 수신 동의 완료했어요!
        </div>
      </div>
      <div v-else-if="hasGroupManagement" class="message-container__group">
        <div class="message-container__group__title">
          <b class="red">[필수]</b>
          조편성 설문 제출
        </div>
        <ul>
          <li><span class="red">설문 미제출 시, 지역이 랜덤 배정</span>되니 기한 내 설문을 제출해 주세요.</li>
          <li>조편성 설문은 <u>마이페이지 > 내강의실 > 조편성</u>에서 다시 확인하실 수 있습니다.</li>
        </ul>
      </div>
    </div>
    <template #footer>
      <template v-if="isReferralTestProduct">
        <Button severity="primary" label="확인" @click="() => handleDialogButton()" />
      </template>
      <template v-else-if="isWeolbuStudyProduct && !!weolbuStudyCTAString">
        <Button severity="primary" :label="weolbuStudyCTAString" @click="() => handleDialogButton('weolbu-study')" />
      </template>
      <template v-else-if="hasGroupManagement">
        <Button severity="primary" label="조편성 설문 작성하기" @click="() => handleDialogButton('survey')" />
      </template>
      <template v-else-if="isGiftCard">
        <Button label="상품권 보러가기" severity="primary" @click="() => handleDialogButton('gift-card')" />
      </template>
      <template v-else>
        <Button
          label="구매 내역 보기"
          severity="secondary"
          outlined
          @click="() => handleDialogButton('purchase-history')" />
        <Button label="강의 보기" severity="primary" @click="() => handleDialogButton(false)" />
      </template>
    </template>
  </Dialog>
</template>

<style lang="scss">
@mixin text-clamp($lines: 2, $line-height: false) {
  display: -webkit-box;
  overflow: hidden;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  word-break: break-all;
  @if $line-height {
    max-height: $line-height * $lines * 1px;
  }
}
.complete-purchase-dialog {
  width: 500px;
  @media screen and (max-width: $md) {
    min-width: 100dvw;
    width: 100%;
    margin: 0 !important;
    max-height: 100% !important;
    height: 100dvh;
  }
  .p-dialog-header {
    padding: 44px 40px;
    display: flex;
    justify-content: center;
    position: relative;
    .button-container {
      position: absolute;
      display: flex;
      align-items: flex-end;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
    .text-container {
      color: #1c2a4b;
      text-align: center;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media screen and (max-width: $md) {
        margin-bottom: 0px;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: flex-start;
        padding-bottom: 16px;
        width: 100%;
        img {
        }
      }
    }
  }
  .p-dialog-content {
    padding: 0px;
  }
  .message-container {
    padding: 48px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: $md) {
      padding: 40px 16px 16px;
      min-height: calc(var(--vh, 1vh) * 100 - 140px);
    }
    &__complete-purchase {
      padding: 40px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: $md) {
        padding: 28px 16px 16px;
        min-height: calc(var(--vh, 1vh) * 100 - 140px);
      }
    }
    &__complete-purchase_class-message {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: #788194;
      padding-top: 8px;
    }
    &__weolbustudy {
      border-top: 1px solid #dddfe4;
      width: 100%;
      padding-top: 20px;
      color: #788194;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &__product {
      display: flex;
      width: 100%;
      height: 62px;
      cursor: pointer;
      &__thumbnail-img {
        margin-right: 10px;
        img {
          width: 110px;
          height: 62px;
          flex-shrink: 0;
          border-radius: 8px;
          @media screen and (max-width: $md) {
            border-radius: 4px;
          }
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 2px;
        line-height: 20px;
        .product-title {
          color: #1c2a4b;
          font-weight: 500;
          @include text-clamp(2);
        }
        .product-desc {
          color: #656f85;
          font-weight: 400;
          @include text-clamp(1);
        }
      }
    }

    &__title {
      @media screen and (max-width: $md) {
        width: 100%;
        color: #000;
        font-family: Pretendard;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px; /* 131.25% */
        margin-bottom: 28px;
        gap: 4px;
      }
    }

    &__group {
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-size: 16px;
      margin-top: 20px;
      @media screen and (max-width: $md) {
        width: 100%;
        font-size: 14px;
      }
      &__title {
        padding: 10px 12px;
        border-radius: 4px;
        border: 1px solid #dddfe4;
        background: #f7f8f9;
        color: #1c2a4b;
        font-family: Pretendard;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      ul {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        list-style: disc;
        font-size: 14px;
        font-weight: 400;
        word-break: keep-all;
        color: #1c2a4b;
        gap: 2px;
        li {
          line-height: 20px;
        }
      }
      .red {
        color: #e34935;
      }
    }
  }
  .p-dialog-header {
    padding: 56px 40px 0px;
    @media screen and (max-width: $md) {
      padding: 16px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }
  .p-dialog-footer {
    display: flex;
    padding: 0px 40px 56px;
    gap: 8px;
    @media screen and (max-width: $md) {
      padding: 12px 16px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .p-button {
      margin: 0;
      width: 100%;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      @media screen and (max-width: $md) {
        border-radius: 4px;
      }
    }
    .p-button-label {
      font-weight: 700;
      line-height: 24px;
      @media screen and (max-width: $md) {
        line-height: 18px;
      }
    }
  }
}
</style>
